<template functional>
  <article>
    <h2 class="font-bold underlined" v-html="props.heading"></h2>
    <p v-html="props.paraghText" class="font-medium"></p>
  </article>
</template>

<script>
export default {
  name: "Articals",
  props: {
    heading: {
      type: String,
      required: true
    },
    paraghText: {
      type: String,
      required: true
    }
  }
};
</script>
